import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Books from "./pages/books";
import Playlists from "./pages/playlists";
import Writing from "./pages/writing";
import "./styles.scss";

function App() {
  return (
    <Router>
      <div className="app">
        <h1 className="main-heading">Welcome to stidlio</h1>
        <nav className="main-nav">
          <ul>
            <li>
              <Link to="/books">Books</Link>
            </li>
           {/*  <li>
              <Link to="/playlists">Playlists</Link>
            </li>
            <li>
              <Link to="/writing">Writing</Link>
            </li> */}
          </ul>
        </nav>

        <Routes>
          <Route path="/books" element={<Books />} />
          <Route path="/playlists" element={<Playlists />} />
          <Route path="/writing" element={<Writing />} />
          <Route path="/" element={<Books />} /> {/* Default route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
