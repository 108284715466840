interface Book {
  author: string;
  title: string;
  note?: string;
}

type BooksByYear = {
  [year: number]: Book[];
};

const booksByYear: BooksByYear = {
  2024: [
    { author: "Frank Herbert", title: "Dune" },
    { author: "Fjodor Dostojewski", title: "Erzählungen aus dem Kellerloch" },
    { author: "Stefan Zweig", title: "Ungeduld des Herzens" },
    { author: "Robert Greene", title: "Mastery", note: "70%" },
    { author: "Rick Rubin", title: "The Creative Act" },
  ],
  2023: [
    { author: "Edmund de Waal", title: "Der Hase mit den Bernsteinaugen" },
    { author: "Boris Johnson", title: "The Churchill Factor" },
    { author: "Pete Dyson & Rory Sutherland", title: "Transport for Humans" },
    {
      author: "Tom Holland",
      title: "Dominion: The Making of the Western Mind",
    },
    { author: "Stefan Zweig", title: "Sternstunden der Menschheit" },
    { author: "Bronnie Ware", title: "Top 5 regrets of the dying" },
  ],
  2022: [
    { author: "Ben Horowitz", title: "The hard thing about hard things" },
    {
      author: "Charles Bukowski",
      title: "Das schlimmste kommt noch oder Fast eine Jugend",
    },
    { author: "Jared Diamond", title: "Guns, Germs and Steel" },
    { author: "Umberto Eco", title: "Das Foucaultsche Pendel", note: "partly" },
    { author: "Dante Alighieri", title: "Die göttliche Komödie" },
  ],
  2021: [
    { author: "Ashlee Vance", title: "Elon Musk" },
    { author: "Don Norman", title: "The Design of Everyday Things" },
    {
      author: "Jimmy Soni, Rob Goodman",
      title: "A Mind At Play (How Claude Shannon Invented the Information Age)",
    },
    { author: "James Gleick", title: "Chaos" },
    { author: "Michel Houllebecq", title: "Elementarteilchen" },
    {
      author: "Sarah Bakewell",
      title: "Wie soll ich leben? Das Leben des Michel Montaigne",
    },
    {
      author: "William B Irvine",
      title: "A Guide To The Good Life - the ancient art of stoic joy",
    },
    { author: "Khaled Hosseini", title: "The Kite Runner" },
  ],
  2020: [
    { author: "Matthias Strolz", title: "Sei Pilot deines Lebens" },
    { author: "Marc Aurel", title: "Selbstbetrachtungen" },
    {
      author: "Michael Köhlmeier, Konrad Paul Liessmann",
      title: "Wer hat dir gesagt, dass du nackt bist, Adam?",
    },
    {
      author: "Richard P Feynman",
      title: "Surely you must be joking, Mr. Feynman?",
    },
    { author: "Homer", title: "Odyssee" },
    {
      author: "Christian Seiler",
      title: "André Heller Feuerkopf. Die Biografie",
    },
    { author: "Robert C. Martin", title: "Clean Coder" },
    { author: "Nassim Nicholas Taleb", title: "The Black Swan" },
    {
      author: "Karl Popper",
      title:
        "Die Offene Gesellschaft und Ihre Feinde -- Band I Der Zauber Platons",
    },
    { author: "Michael Köhlmeier", title: "Geschichten von der Bibel" },
    { author: "Nassim Nicholas Taleb", title: "Fooled by Randomness" },
    {
      author: "Karl Popper",
      title:
        "Die offene Gesellschaft und ihre Feinde -- Band II Falsche Propheten: Hegel, Marx und die Folgen",
    },
    { author: "Adolf Holl", title: "Jesus in schlechter Gesellschaft" },
    { author: "Seneca", title: "Über die Gelassenheit" },
    { author: "Viktor E Frankl", title: "Trotzdem Ja zum Leben sagen" },
    { author: "Ben Horowitz", title: "What you do is who you are" },
    { author: "Vaclav Smil", title: "Energy" },
    { author: "Stefanie Sargnagel", title: "Dicht" },
    {
      author: "Gabriel Garcia Marquez",
      title: "Die Liebe in den Zeiten der Cholera",
    },
  ],
  2019: [
    {
      author: "Wolf Lotter",
      title: "Innovation Streitschrift für barrierefreies Denken",
    },
    { author: "Alexander Solschenizyn", title: "Der Archipel Gulag" },
    { author: "Nassim Nicholas Taleb", title: "Skin In The Game" },
    { author: "Steven Hawking", title: "Eine kurze Geschichte der Zeit" },
    {
      author: "Peter Theil , Blake Masters",
      title: "Zero To One",
      note: "Reread",
    },
    { author: "Nassim Nicholas Taleb", title: "Antifragility" },
    { author: "Friedrich Nietzsche", title: "Jenseits von Gut und Böse" },
    { author: "Homer", title: "Ilias" },
    { author: "Friedrich Nietzsche", title: "Also sprach Zarathustra" },
  ],
  2018: [
    { author: "Mariana Mazzucato", title: "The Entrepreneurial State" },
    { author: "Stefan Zweig", title: "Die Welt von Gestern" },
    {
      author: "Kurt Rothschild",
      title: "Die politischen Visionen großer Ökonomen",
      note: "Literatur zur Bachelorarbeit",
    },
    {
      author: "Jürgen Kromphardt",
      title: "Die größten Ökonomen : John Maynard Keynes",
      note: "Literatur zur Bachelorarbeit",
    },
    {
      author: "J.M.Keynes",
      title: "Allgemeine Theorie",
      note: "Literatur zur Bachelorarbeit",
    },
    { author: "Yuval Noah Harari", title: "Homo Deus" },
    { author: "Peter Thiel", title: "Zero To One" },
    { author: "Nassim Nicholas Taleb", title: "Bett des Prokrustres" },
    { author: "Alec Ross", title: "The Industries Of The Future" },
    { author: "Lew Tolstoi", title: "Krieg und Frieden" },
    { author: "Jonathan Haidt", title: "The Righteous Mind" },
    { author: "Branko Milanovic", title: "Global Inequality" },
    { author: "Bertrand Russel", title: "Unpopuläre Beobachtungen" },
    { author: "Jordan B. Peterson", title: "Twelve Rules For Life" },
    {
      author: "David Foster Wallace",
      title: "Unendlicher Spaß",
      note: "unvollständig",
    },
    {
      author: "Various",
      title: "Die Bibel (Genesis, Exodus, Ev. Matthäus)",
      note: "In Auszügen",
    },
  ],
  2017: [
    { author: "Stephen Green", title: "The European Identity" },
    { author: "Milton Friedman", title: "Kapitalismus und Freiheit" },
    {
      author: "Alice Schroeder",
      title: "Warren Buffet-Das Leben ist wie ein Schneeball",
    },
    { author: "Lukas Haffert", title: "Die schwarze Null" },
    { author: "Robert J Shiller", title: "Irrational Exuberance" },
    { author: "Benjamin Graham", title: "The Intelligent Investor" },
    { author: "Michael Köhlmeier", title: "Sagen des Klassischen Altertums" },
    { author: "Bertrand Russel", title: "Philosophie des Abendlandes" },
    {
      author: "John Maynard Keynes",
      title: "Allgemeine Theorie der Beschäftigung, des Zinses und des Geldes",
      note: "Nicht fertig",
    },
    { author: "Daniel Kahnemann", title: "Schnelles Denken, Langsames Denken" },
    { author: "Robert Menasse", title: "Die Hauptstadt" },
    {
      author: "Yuval Noah Harari",
      title: "Eine kleine Geschichte der Menschheit",
    },
  ],
  2016: [
    { author: "Michel Houellebecq", title: "Unterwerfung" },
    { author: "Slavoj Zizek", title: "Der neue Klassenkampf" },
    { author: "Voltaire", title: "Über die Toleranz", note: "partly" },
    {
      author: "George Packer",
      title: "Die Abwicklung ;Eine innere Geschichte des Neuen Amerika",
    },
    { author: "Joseph Stiglitz", title: "The Great Divide" },
    { author: "Philip Roth", title: "Der Menschliche Makel" },
    { author: "Alfred Andersch", title: "Sansibar oder der letzte Grund" },
    { author: "Eric Ries", title: "The Lean Startup" },
    { author: "Various", title: "Blockchain Revolution", note: "partly" },
  ],
  2015: [
    {
      author: "Jonas Jonasson",
      title: "Der hundertjährige der aus dem Fenster stieg und verschwand",
    },
    { author: "Karl Marx", title: "Das Kapital" },
    {
      author: "Karl Marx/Friedrich Engels",
      title: "Das Kommunistische Manifest",
    },
    { author: "Brigitte Schwaiger", title: "Wie kommt das Salz ins Meer?" },
    { author: "Thilo Sarrazin", title: "Deutschland schafft sich ab" },
    { author: "George Orwell", title: "1984" },
    { author: "Heinz Bude", title: "Gesellschaft der Angst" },
    { author: "Diverse", title: "Die Simpsons und die Philosophie" },
    {
      author: "Richard David Precht",
      title: "Wer bin ich- und wenn ja,wie viele ?",
    },
    { author: "Albert Camus", title: "Der Mhytos des Sisyphos" },
    { author: "Hamed Abdel-Samad", title: "Der Islamische Faschismus" },
    {
      author: "Axel Madsen",
      title: "Die Geschichte einer ungewöhnlichen Liebe (Sartre u Beauvoir)",
    },
    { author: "Alexander van der Bellen", title: "Die Kunst der Freiheit" },
  ],
};

const Books = () => {
  const years = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015];
  return (
    <div>
      <h2>Books I've Read</h2>
      <p>
        I started tracking the books I've read back in 2015 mostly because it
        gave me a good feeling to produce a long list over the course of a year.
        In the past 2-3 years I've changed to a less vanity driven approach, and
        really take my time pondering and rabbit-holeing into the best books and
        just immediately quit books the moment they bore me.
        <br />
        I'm also doing a lot of rereading of my favorites. It's probably the
        best indicator if a book was enjoyable and valuable to me if I happen to
        reread it after some time has passed.
      </p>
      <p>Will be adding ratings/comments and english titles here shortly!</p>

      {years.map((year) => (
        <div key={year} className="year-section">
          <h3>{year}</h3>
          <ul>
            {booksByYear[year]
              .slice()
              .reverse()
              .map((book, index) => (
                <li key={index}>
                  {book.author} - {book.title}
                  {book.note && (
                    <span className="book-note"> ({book.note})</span>
                  )}
                </li>
              ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
export default Books;
